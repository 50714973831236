export const defaultsave = "defaultsave";
export const defaultList = "defaultList";
export const edit = "edit";
export const view = "view";
export const copy = "copy";
export const isdelete = "isdelete";
export const completed = "completed";



export const viewApproval = "viewApproval";
export const isSendToScm = "isSendToScm";
export const makeGoodsCreditNote = "makeGoodsCreditNote";

export const isShowImages = "isShowImages";




export const dropdownAdd = "dropdownAdd";
export const modeSTPsave = "modeSTPsave";//for in the list page save button show purpose
export const modeSTPList = "modeSTPList"; // only list page and buttons
export const orderApproval = "orderApproval";
export const hide = "hide";

export const makeBtn = "makeBtn";
export const download = "download";
export const upload = "upload";
export const MinPrint = "MinPrint";

export const ThermalPrint = "ThermalPrint";



export const otherBtn_1 = "otherBtn_1";
export const MultiInvoice = "MultiInvoice";

export const updateDetails = "updateDetails";


export const CustomerWiseSummary = "CustomerWiseSummary";
export const ItemWiseSummary = "ItemWiseSummary";
export const MastarClaimSummary = "MastarClaimSummary";



export const editValue = "editValue" //Only for variable
export const assingLink = "assingLink" //Only for order page assing Item Link



